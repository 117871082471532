@charset "UTF-8";
:root {
  --accordeonHeaderBg: #f28c00;
  --accordeonHeaderBgHover: #f28c00;
  --accordeonTitle: #fff;
  --bgColor: #f0f4f7;
  --bgcolorAlert: #f5d0d1;
  --borderRadius: 0px;
  --chipsColor: #ccd9e4;
  --colorAlert: #cd1619;
  --colorAlertHover: #f7ba66;
  --colorAlertActive: #f7ba66;
  --colorAlertText: #fff;
  --colorBaseButton: #fff;
  --colorBaseButtonHover: #ccd9e4;
  --colorBaseButtonActive: #ccd9e4;
  --colorBaseButtonInactive: #fff;
  --colorBaseButtonText: #000;
  --colorButton: #004079;
  --colorButtonHover: #668caf;
  --colorButtonActive: #99b3c9;
  --colorButtonText: #fff;
  --colorFocus: #a0c11a;
  --colorIcon: #004079;
  --colorInfo: #f28c00;
  --colorInfoHover: #f7ba66;
  --colorInfoActive: #f7ba66;
  --colorInfoText: #000;
  --colorLabel: #40607b;
  --colorPrimary: #004079;
  --colorPrimaryHover: #668caf;
  --colorPrimaryActive: #668caf;
  --colorPrimaryText: #fff;
  --colorSuccess: #a0c11a;
  --colorSuccessHover: #c6da76;
  --colorSuccessActive: #c6da76;
  --colorSuccessText: #000;
  --colorWarning: #f28c00;
  --colorWarningHover: #f7ba66;
  --colorWarningActive: #f7ba66;
  --colorWarningText: #000;
  --colorRowHover: #ccd9e4;
  --colorRowSelected: #99b3c9;
  --darkGrey: #004079;
  --dashBoardBgImg: none;
  --defaultPadding: 5px;
  --font-size: 12px;
  --grid-row-color: #fff;
  --hover-color: red;
  --icons-path: ~ag-grid/src/styles/icons/;
  --inputBdrColor: #aaaaaa;
  --inputBdrSearchColor: #aaaaaa;
  --inputBgColor: #ffffff;
  --inputdisabledBdrColor: #f6f6f6;
  --inputdisabledBgColor: #f6f6f6;
  --lightGrey: #99b3c9;
  --mainBgColor: #ccd9e4;
  --ongletHover: #ccd9e4;
  --odd-row-background-color: #f5f5f5;
  --quickOrder: #ccd9e4;
  --shadowBox: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  --text-color: #095b9e;
  --textColor: #095b9e;
  --thHeaderGrid: #004079;
  --titleHeaderGrid: #fff;
  /*Guides*/
  --disabled-opacity: 0.5;
  --grid-row-color: #fff;
  --font-family-Alt: 'Fira Sans Condensed', sans-serif;
  --font-family: 'Fira Sans Condensed', sans-serif;
  --accordeon-padding: 0.55em 1em;
  --darkGrey: #004079;
  --shortcut-title-color: #668caf;
  --shortcut-title-color-hover: #004079;
  --notification-point-color: #a0c11a;
  --notification-point-color-hover: #8eac17;
  --menu-hover-color: #ccd9e4;
  --border-radius-circle: 50%;
  --border-radius: 0px;
  --border-radius-pin: 4px;
}
body {
  iu-prime-fileupload div > div p-button {
    margin-right: 3px;
    margin-left: 3px;
  }
  button {
    border: 0;
  }
  body .wd-menu-top-show .ui-button {
    background-color: var(--bgColor) !important;
    border-color: var(--bgColor) !important;
    color: var(--textColor) !important;
    font-size: 12px;
  }
  button > span.iupics-badge-count {
    position: absolute;
    right: 1px;
    top: 0px;
    padding-left: 2px;
    padding-right: 2px;
    background-color: var(--colorInfo);
    color: #fff;
    font-size: 8px;
    z-index: 8;
    border-radius: var(--border-radius-circle);
    width: 15px;
    height: 15px;
    line-height: 15px;
  }
  iu-location-panel div.locationPanel input.ui-inputtext {
    width: calc(100% - 26px);
  }
  :disabled {
    opacity: 0.5;
  }
  .ui-toolbar-group-center .tab {
    border-radius: var(--border-radius);
  }
  .appVersionPanelContainer .version .versionName {
    font-size: 10px;
  }

  .ag-theme-balham .ag-row {
    border-color: var(--grid-row-color);
  }

  .user-profile-logoff-btn {
    background-color: var(--colorWarning) !important;
    border-color: var(--colorWarning) !important;

    &:hover {
      background-color: var(--colorWarningHover) !important;
      border-color: var(--colorWarningHover) !important;
    }
  }

  .ui-fieldset .ui-fieldset-legend a {
    padding: var(--accordeon-padding);
    border-bottom: 1px solid #fff;
  }

  .ui-fieldset .ui-fieldset-legend-text {
    vertical-align: middle;
    color: var(--darkGrey);
  }

  ul.shortcutsTitle li a {
    display: block;
    color: var(--shortcut-title-color);
    padding: 6px 3px 0px 3px;
    white-space: nowrap;
  }

  .fas.fa-check:before,
  .fa.fa-check:before {
    content: '' !important;
  }

  .ui-chkbox .ui-chkbox-box .ui-chkbox-icon.ui-clickable::before {
    color: var(--colorPrimaryHover);
  }
  .ui-button {
    font-size: 1em;
  }

  .wd-menu-top-show .notif button.ui-button span.pin {
    background-color: var(--notification-point-color);
    &:hover {
      background-color: var(--notification-point-color-hover);
    }
  }

  .ui-accordion .ui-accordion-header a .ui-accordion-toggle-icon.pi-caret-right:before,
  .pi-chevron-right:before,
  .ui-accordion .ui-accordion-header a .ui-accordion-toggle-icon.pi-caret-down:before,
  .pi-chevron-down:before,
  .ui-accordion .ui-accordion-toggle-icon,
  .ui-accordion .ui-accordion-header-text {
    color: white;
  }
  .menuItemSelected {
    background-color: var(--menu-hover-color);
  }
  button.workspace.ui-button {
    font-family: var(--font-family);
    background-color: var(--colorPrimary);
    border-color: var(--colorPrimary);
    color: #ffffff;
    padding-right: 13px;
    padding-left: 28px;
    background-image: url('../iupics/img/logo-a-apiz-alt.png');

    &.active {
      background-image: url('../iupics/img/logo-a-apiz.png');
    }
  }

  .menuItem {
    &:not(.ghost-elt):hover {
      background-color: var(--menu-hover-color);
      cursor: pointer;
    }
  }

  favorites-ui,
  widget-ui {
    > div.box {
      border-radius: var(--border-radius) !important;
    }
  }

  .ag-cell {
    span.autocompleteRenderer,
    .numberRenderer,
    .calendarRenderer.defaultDateCalendar.ui-g-12 {
      padding: 0;
      .input-number-body input.iu-number-input {
        width: 100%;
      }
    }
  }
  .iu-specific-window-container {
    background-color: white;
    padding: 0 10px 0 10px;
    margin: 10px;
    border-radius: 10px;
    height: calc(100% - 20px);
    overflow-y: auto;
  }

  //icon perso
  [class^='gcb-'],
  [class*=' gcb-'] {
    &::before {
      content: '';
      height: 16px;
      width: 16px;
      display: block;
      background-repeat: no-repeat;
    }
  }
  i.menuIcon {
    display: inline-block;
    &[class^='gcb-'],
    &[class*=' gcb-'] {
      &::before {
        height: 48px;
        width: 48px;
      }
    }
  }
  .gcb-acces-petit:before {
    background-image: url('img/Accès - petit.svg');
  }

  .gcb-acces-2:before {
    background-image: url('img/Accès 2.svg');
  }

  .gcb-acces-3:before {
    background-image: url('img/Accès 3.svg');
  }

  .gcb-acces-4-2:before {
    background-image: url('img/Accès 4.2.svg');
  }

  .gcb-acces-4:before {
    background-image: url('img/Accès 4.svg');
  }

  .gcb-acces:before {
    background-image: url('img/Accès.svg');
  }

  .gcb-camp-etranger-petit:before {
    background-image: url("img/Camp à l'étranger - petit.svg");
  }

  .gcb-camp-etranger:before {
    background-image: url("img/Camp à l'étranger.svg");
  }

  .gcb-covid19:before {
    background-image: url('img/Covid-19.svg');
  }

  .gcb-groupe-petit:before {
    background-image: url('img/Groupe - petit.svg');
  }

  .gcb-groupe:before {
    background-image: url('img/Groupe.svg');
  }

  .gcb-intrepides:before {
    background-image: url('img/Intrépides.svg');
  }

  .gcb-processus-gestion-2-2:before {
    background-image: url('img/Processus-gestion (2).svg');
  }

  .gcb-processus-gestion-2:before {
    background-image: url('img/Processus-gestion 2.svg');
  }

  .gcb-processus-gestion-petit:before {
    background-image: url('img/Processus-gestion - petit.svg');
  }

  .gcb-s-scribe-sans-fleche-blanc:before {
    background-image: url('img/S scribe - SANS FLECHE - blanc.svg');
  }

  .gcb-s-scribe-sans-fleche-bleu:before {
    background-image: url('img/S scribe - SANS FLECHE - bleu.svg');
  }

  .gcb-s-scribe-blanc:before {
    background-image: url('img/S scribe blanc.svg');
  }

  .gcb-s-scribe-bleu:before {
    background-image: url('img/S scribe bleu.svg');
  }

  .gcb-trefle-blanc:before {
    background-image: url('img/Trefle-blanc.svg');
  }

  .gcb-trefle-couleurs:before {
    background-image: url('img/Trefle-couleurs.svg');
  }

  .gcb-trefle:before {
    background-image: url('img/Trefle.svg');
  }

  .gcb-telecharger-petit:before {
    background-image: url('img/Télécharger - petit.svg');
  }

  .gcb-telecharger-2:before {
    background-image: url('img/Télécharger 2.svg');
  }

  .gcb-telecharger-3:before {
    background-image: url('img/Télécharger 3.svg');
  }

  .gcb-telecharger-4:before {
    background-image: url('img/Télécharger 4.svg');
  }

  .gcb-individu-petit:before {
    background-image: url('img/Un individu - petit.svg');
  }

  .gcb-individu-2:before {
    background-image: url('img/Un individu 2.svg');
  }

  .gcb-individu:before {
    background-image: url('img/Un individu.svg');
  }

  .gcb-unite-petit:before {
    background-image: url('img/Unité - petit.svg');
  }

  .gcb-unite:before {
    background-image: url('img/Unité.svg');
  }

  .gcb-agenda-date:before {
    background-image: url('img/agenda-date.svg');
  }

  .gcb-animateurs:before {
    background-image: url('img/animateurs.svg');
  }

  .gcb-attention-offline-1:before {
    background-image: url('img/attention-offline-1.svg');
  }

  .gcb-attention-offline:before {
    background-image: url('img/attention-offline.svg');
  }

  .gcb-aventure:before {
    background-image: url('img/aventure.svg');
  }

  .gcb-bibliotheque:before {
    background-image: url('img/bibliothèque.svg');
  }

  .gcb-bon:before {
    background-image: url('img/bon.svg');
  }

  .gcb-burger:before {
    background-image: url('img/burger.svg');
  }

  .gcb-camps:before {
    background-image: url('img/camps.svg');
  }

  .gcb-carrick:before {
    background-image: url('img/carrick.svg');
  }

  .gcb-check:before {
    background-image: url('img/check.svg');
  }

  .gcb-close:before {
    background-image: url('img/close.svg');
  }

  .gcb-collaboratif:before {
    background-image: url('img/collaboratif.svg');
  }

  .gcb-communication:before {
    background-image: url('img/communication.svg');
  }

  .gcb-comptabilite:before {
    background-image: url('img/comptabilite.svg');
  }

  .gcb-crise:before {
    background-image: url('img/crise.svg');
  }

  .gcb-document:before {
    background-image: url('img/document.svg');
  }

  .gcb-dossier:before {
    background-image: url('img/dossier.svg');
  }

  .gcb-dots:before {
    background-image: url('img/dots.svg');
  }

  .gcb-droite:before {
    background-image: url('img/droite.svg');
  }

  .gcb-ecoactif:before {
    background-image: url('img/ecoactif.svg');
  }

  .gcb-economat-polo:before {
    background-image: url('img/economat-polo.svg');
  }

  .gcb-espace-partage:before {
    background-image: url('img/espace de partage.svg');
  }

  .gcb-evenements:before {
    background-image: url('img/evenements.svg');
  }

  .gcb-facebook:before {
    background-image: url('img/facebook.svg');
  }

  .gcb-fait:before {
    background-image: url('img/fait.svg');
  }

  .gcb-famille:before {
    background-image: url('img/famille.svg');
  }

  .gcb-formation:before {
    background-image: url('img/formation.svg');
  }

  .gcb-formulaire:before {
    background-image: url('img/formulaire.svg');
  }

  .gcb-formulaire-2:before {
    background-image: url('img/formulaire (2).svg');
  }

  .gcb-formulaire-petit:before {
    background-image: url('img/formulaire - petit.svg');
  }

  .gcb-gauche:before {
    background-image: url('img/gauche.svg');
  }

  .gcb-google-plus:before {
    background-image: url('img/google plus.svg');
  }

  .gcb-horizon:before {
    background-image: url('img/horizon.svg');
  }

  .gcb-idee:before {
    background-image: url('img/idee.svg');
  }

  .gcb-image:before {
    background-image: url('img/image.svg');
  }

  .gcb-instagram:before {
    background-image: url('img/instagram.svg');
  }

  .gcb-intendance:before {
    background-image: url('img/intendance.svg');
  }

  .gcb-jeux:before {
    background-image: url('img/jeux.svg');
  }

  .gcb-jour:before {
    background-image: url('img/jour.svg');
  }

  .gcb-linkedin:before {
    background-image: url('img/linkedin.svg');
  }

  .gcb-logistique:before {
    background-image: url('img/logistique.svg');
  }

  .gcb-logout:before {
    background-image: url('img/logout.svg');
  }

  .gcb-lutin:before {
    background-image: url('img/lutin.svg');
  }

  .gcb-mail-message:before {
    background-image: url('img/mail-message.svg');
  }

  .gcb-mauvais:before {
    background-image: url('img/mauvais.svg');
  }

  .gcb-nuit:before {
    background-image: url('img/nuit.svg');
  }

  .gcb-nuton:before {
    background-image: url('img/nuton.svg');
  }

  .gcb-outils:before {
    background-image: url('img/outils.svg');
  }

  .gcb-ouvrage:before {
    background-image: url('img/ouvrage.svg');
  }

  .gcb-parametres:before {
    background-image: url('img/parametres.svg');
  }

  .gcb-partenaires:before {
    background-image: url('img/partenaires.svg');
  }

  .gcb-participants:before {
    background-image: url('img/participants.svg');
  }

  .gcb-pasfait:before {
    background-image: url('img/pasfait.svg');
  }

  .gcb-photo:before {
    background-image: url('img/photo.svg');
  }

  .gcb-plan-adresse:before {
    background-image: url('img/plan-adresse.svg');
  }

  .gcb-plus:before {
    background-image: url('img/plus.svg');
  }

  .gcb-progression:before {
    background-image: url('img/progression.svg');
  }

  .gcb-progression-2:before {
    background-image: url('img/progression 2.svg');
  }

  .gcb-progression-2-1:before {
    background-image: url('img/progression (2).svg');
  }

  .gcb-progression-2-2:before {
    background-image: url('img/progression 2.2.svg');
  }

  .gcb-publication:before {
    background-image: url('img/publication.svg');
  }

  .gcb-qualis:before {
    background-image: url('img/qualis.svg');
  }

  .gcb-recherche:before {
    background-image: url('img/recherche.svg');
  }

  .gcb-rediger-ecrire:before {
    background-image: url('img/rediger-ecrire.svg');
  }

  .gcb-route:before {
    background-image: url('img/route.svg');
  }

  .gcb-scribe:before {
    background-image: url('img/scribe.svg');
  }

  .gcb-soins-sante:before {
    background-image: url('img/soins-sante.svg');
  }

  .gcb-telephone-appel:before {
    background-image: url('img/telephone-appel.svg');
  }

  .gcb-temps-durees:before {
    background-image: url('img/temps-durees.svg');
  }

  .gcb-totebag:before {
    background-image: url('img/totebag.svg');
  }

  .gcb-totem:before {
    background-image: url('img/totem.svg');
  }

  .gcb-twitter:before {
    background-image: url('img/twitter.svg');
  }

  .gcb-uniformes:before {
    background-image: url('img/uniformes.svg');
  }

  .gcb-waiting:before {
    background-image: url('img/waiting.svg');
  }

  .gcb-whatsapp:before {
    background-image: url('img/whatsapp.svg');
  }

  .gcb-youtube:before {
    background-image: url('img/youtube.svg');
  }
}
